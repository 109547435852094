import { http } from '@/utils'
import { ArrowUpOutlined, CheckOutlined } from '@ant-design/icons'
import { Breadcrumb, Card, Flex, Spin, Statistic, Timeline, Typography, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const { Title } = Typography

interface StatisticsType {
  batch_ing_qty: number
  stock_ing_qty: number
  annual_yield: number
  expect_annual_yield: number
}

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [statistics, setStatistics] = useState<StatisticsType>({} as StatisticsType)

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      try {
        const res = await http.post('/v1/home/dashboard')
        setStatistics({ ...res.data })
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error(error.response?.data?.message || '失败')
        }
      }
      setLoading(false)
    }
    loadData()
  }, [])

  return (
    <Spin
      size="large"
      spinning={loading}
    >
      <Flex
        justify="space-between"
        gap="small"
        vertical
      >
        <Breadcrumb
          style={{ margin: '16px 0 5px 0' }}
          separator=">"
          items={[
            {
              // title: 'Dashboard',
            },
          ]}
        />
        <Flex
          justify="space-evenly"
          gap="small"
        >
          <Card style={{ width: '100%', textAlign: 'center' }}>
            <Title
              level={4}
              style={{ margin: '5px 0 10px 0' }}
            >
              2024 赛季
            </Title>
          </Card>
        </Flex>

        <Flex
          justify="space-between"
          gap="small"
        >
          <Card style={{ width: '305px', textAlign: 'center' }}>
            <Timeline
              mode="left"
              items={[
                {
                  color: 'gray',
                  label: '2024年10月 底',
                  children: '双增',
                },
                {
                  color: 'gray',
                  label: '2024年10月 中',
                  children: '单增',
                },
                {
                  color: 'gray',
                  label: '2024年8月 底',
                  children: '双增',
                },
                {
                  color: 'green',
                  dot: <CheckOutlined />,
                  label: '2024年7月 中',
                  children: '单增',
                },
                {
                  color: 'green',
                  dot: <CheckOutlined />,
                  label: '2024年4月 底',
                  children: '双增',
                },
                {
                  color: 'green',
                  dot: <CheckOutlined />,
                  label: '2024年4月 中',
                  children: '单增',
                },
                {
                  color: 'green',
                  dot: <CheckOutlined />,
                  label: '2024年2月 底',
                  children: '双增',
                },
                {
                  color: 'green',
                  dot: <CheckOutlined />,
                  label: '2024年1月 底',
                  children: '单增',
                },
              ]}
            />
          </Card>
          <Flex
            justify="flex-start"
            gap="small"
            vertical={true}
            style={{ width: '100%' }}
          >
            <Card style={{ width: '100%', textAlign: 'center' }}>
              <Statistic
                title="运行中的股池"
                value={statistics.batch_ing_qty}
                valueStyle={{ color: '#7265e6' }}
              />
            </Card>
            <Card style={{ width: '100%', textAlign: 'center' }}>
              <Statistic
                title="运行中的证券"
                value={statistics.stock_ing_qty}
                valueStyle={{ color: '#7265e6' }}
              />
            </Card>
            <Card style={{ width: '100%', textAlign: 'center' }}>
              <Statistic
                title="年化收益率(已结束)"
                value={statistics.annual_yield}
                precision={2}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />
            </Card>
            <Card style={{ width: '100%', textAlign: 'center' }}>
              <Statistic
                title="期望年化收益率"
                value={statistics.expect_annual_yield}
                precision={2}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />
            </Card>
          </Flex>
        </Flex>
      </Flex>
    </Spin>
  )
}

export default Dashboard
